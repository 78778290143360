.form-check {
  &[class*="col-"] {
    padding-left: 0.75rem;
  }

  &:not([class*="col-"]) {
    align-items: flex-start;
    display: flex;
    padding-left: 0;
    &.form-check-inline {
      display: inline-flex;
    }
    &:not(:last-child) {
      margin-bottom: 1.916rem;
    }
    &.has-disabled-note {
      flex-wrap: wrap;
      .form-check-label {
        max-width: calc(100% - #{$form-check-input-width} - 1rem);
      }
    }
  }
  .form-check-input {
    float: none;
    margin-left: 0;
    min-width: 1.875rem;
    &:disabled {
      +.form-check-label {
        cursor: not-allowed;
      }
    }
  }
}

.form-check-label {
  font-size: $font-size-lg;
  margin-left: 0.75rem;
  margin-top: -0.25rem;
}

.form-check-label-em {
  font-size: $font-size-lg;
  margin-left: 0.75rem;
  margin-top: -0.25rem;
  color: #F9C80E;
}