.quick-form {
  .d-none-quick-form {
    display: none;
  }
  .panel:not(:last-child) {
    margin-bottom: 11%;
  }
  legend {
    @extend %legend-serif;
  }
  .gift-review {
    legend {
      color: $primary;
      margin-bottom: 1.5rem;
    }
  }
  .gifts {
    margin-bottom: 11%;
    h2 {
      @extend %legend-serif;
    }
  }
  .gifts-total {
    margin-top: -.5rem;
  }
  .gift-recurrence {
    legend {
      color: $primary;
      margin-bottom: 1.5rem;
    }
  }
}