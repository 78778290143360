.search {
  @extend .narrow-md;
  .form-control {
    @extend %form-control-sm;
    border-color: $blue-lighter;
    &:placeholder-shown {
      text-transform: uppercase;
    }
  }
  .form-select {
    @extend %form-select-sm;
    border-color: $blue-lighter;
  }
}