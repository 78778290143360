.fine-print {
  @extend .narrow-lg;
  margin-top: 3.75rem;
  .btn {
    color: $black;
    font-size: $font-size-sm;
    padding: .75rem 1.75rem;
    text-align: left;
    &:hover {
      background-color: transparent;
      color: $black;
    }
    &:not(.collapsed) {
      background-color: $light;
      background-image: $form-select-indicator-open;
      border-bottom: 0;
      outline: 0;
    }
  }
  .collapse,
  .collapsing {
    background-color: $light;
    border: 1px solid $primary;
    border-top: 0;
    font-size: $font-size-sm;
    font-family: $font-family-serif;
    padding: 1.25rem 1.75rem .75rem;
    .h6 {
      color: $black;
      text-align: left;
    }
  }
}