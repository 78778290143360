.gifts {
  .h4 {
    text-align: left;
  }
  margin-bottom: 5%;
  .btn-group {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }
  .input-group {
    justify-content: flex-end
  }
  .input-group-text {
    font-family: $font-family-serif-alt;
    background-color: transparent;
    border-color: transparent;
    color: $primary;
    font-size: 2.135rem;
    font-weight: $font-weight-bold;
    padding: 0 .75rem 0 0;
  }
  .form-control {
    color: $primary;
    font-family: $font-family-serif;
    font-size: 1.39rem;
    font-weight: $font-weight-bold;
    line-height: 1;
    max-width: 6em;
    padding-bottom: 0;
    padding-top: 0;
    text-align: center;
    &:focus {
      text-align: left;
      ;
    }
  }
}

.add-gift {
  border-bottom: 3px solid;
  margin-bottom: 1.75rem;
  margin-top: .375rem;
  padding-bottom: .5rem;
  .btn {
    font-size: $font-size-lg;
    text-underline-offset: 2px;
    &:before {
      content: '+';
    }
  }
}