.footer {
  padding-bottom: 0.75rem;
  padding-top: 0.75rem;
  div {
    @include media-breakpoint-down(sm) {
      min-width: 100%;
    }
  }
  .navbar-brand {
    @include media-breakpoint-down(sm) {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .navbar-nav {
    font-size: 0.625rem;
    font-weight: $font-weight-semibold;
  }
  .nav-item {
    a {
      @extend .nav-link;
      color: $white;
      @include media-breakpoint-up(sm) {
        &:first-child {
          padding-left: 0;
        }
      }
    }
  }
  p {
    color: $text-muted;
    font-size: $font-size-sm;
    margin: 0;
  }
}