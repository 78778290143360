.outro {
  @extend .narrow;
  margin-top: 3.5rem;
  text-align: left;
  .h6,
  p {
    color: $gray-600;
    margin-bottom: .75rem;
  }
  .h6 {
    text-align: left;
  }
  p {
    @extend .fs-6
  }
}