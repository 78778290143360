.specify-fund {
  @extend .narrow-md;
  margin-top: calc(-11% + 2rem);
  .form-check {
    align-items: center !important;
  }
  .form-check-label {
    font-size: $small-font-size;
  }
  .form-control {
    @extend %form-control-sm;
    border-color: $blue-lighter;
    &:placeholder-shown {
      text-transform: uppercase;
    }
  }
}