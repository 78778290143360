.confirm {
  h2 {
    @extend %gray-h2;
  }
}

.summary {
  font-size: $font-size-lg;
  line-height: 1.2;
  margin-bottom: -.75rem;
  .row {
    &:not(:last-child) {
      margin-bottom: 1rem;
      @include media-breakpoint-down(sm) {
        margin-bottom: 2rem;
      }
    }
  }
  dt {
    font-weight: $font-weight-normal;
  }
  dl {
    font-weight: $font-weight-bold;
    @include media-breakpoint-down(sm) {
      margin-top: .25rem;
    }
  }
}