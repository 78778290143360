$form-feedback-icon-invalid-size: 1rem 1rem;
[class*="row-invalid"] {
  .invalid-feedback {
    display: block;
  }
}

.invalid-feedback {
  font-weight: $font-weight-bold;
  text-align: right;
  &:first-child {
    margin-bottom: -0.5rem;
    text-align: center;
  }
  ~ {
    .invalid-feedback {
      display: none;
    }
  }

  &:not(:empty) + .short-and-sweet-counter {
    position: absolute;
    bottom: 0;
  }
}

.btn-check.is-invalid {
  +.btn {
    border-color: $danger !important;
    border-width: 3px;
    color: $white !important;
  }
  ~.invalid-feedback {
    @extend .visually-hidden;
  }
}

.form-control,
.form-select {
  &.is-invalid {
    border-width: 3px;
  }
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-size: 1rem 1rem;
}

.form-control.other-amount.is-invalid {
  background: none;
  padding-right: 1rem;
}

.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"] {
  background-size: 20px 18px, $form-feedback-icon-invalid-size;
}
.short-and-sweet-counter {
  font-weight: $font-weight-bold;
  font-size: max(0.875rem, 14px);
}

.error-alert-container {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  z-index: 10;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.4);

  &:empty {
    display: none;
  }
}