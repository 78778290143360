.input-layout {
  &.collapse {
    margin-top: 11%;
  }
  
  &:not(:last-child) {
    margin-bottom: 8%;
  }
  legend {
    @extend %text-sans-serif;
    color: $primary;
    margin-bottom: 2.25rem;
  }
  .row {
    --bs-gutter-y: 1.916rem;
  }
  .nav-tabs {
    margin-top: 1rem;
    .btn {
      @include media-breakpoint-down(sm) {
        font-size: $small-font-size;
      }
    }
  }
  .tab-content>.active {
    display: flex;
  }
  .btn-light.active,
  .btn-check:checked+.btn-light {
    &::after {
      @extend %button-indicator;
      border-top-color: $primary;
    }
  }
}