.gift-amount {
  margin-top: 1rem;
  .btn {
    font-size: 1.099375rem;
    font-weight: $font-weight-normal;
  }
  .btn-check:checked+.btn-secondary {
    background-color: $active;
    border-color: $active;
  }
  .input-group-text {
    border-radius: 1.25rem 0 0 1.25rem;
  }
  .form-control {
    border-radius: 1.25rem;
    padding-top: .25rem;
    padding-bottom: .25rem;
    &:placeholder-shown {
      text-align: left !important;
    }
  }
  >[class*=row-invalid] {
    .invalid-feedback {
      text-align: left;
    }
    >.invalid-feedback {
      margin-top: 1rem;
    }
  }
  [class*=col] {
    .invalid-feedback {
      display: none;
    }
  }
}