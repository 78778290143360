%navbar-circle {
  align-items: center;
  display: inline-flex;
  height: 2.625rem;
  justify-content: center;
  width: 2.625rem;
}

.skip {
  &:focus {
    display: inline-block;
    margin: .25rem 1rem;
    outline: 1px solid;
    padding: .25rem;
  }
}

.header {
  margin-bottom: auto;
  h1 {
    @include font-size($h2-font-size);
    color: $white;
    margin: 0;
  }
  .navbar {
    align-items: center;
    &:first-child {
      .nav-link {
        font-size: .78125rem;
        text-transform: uppercase;
      }
    }
    &:last-child {
      padding-bottom: 1.75rem;
      padding-top: 1.75rem;
    }
  }
  .navbar-brand {
    align-items: center;
  }
  .navbar-toggler {
    @extend %navbar-circle;
    border-color: $white;
    margin-left: auto;
    &:hover {
      border-color: $navbar-dark-hover-color;
    }
  }
  .navbar-toggler-icon {
    height: 12px;
    width: 12px;
  }
  .navbar-collapse {
    order: 1;
    text-align: left;
  }
  .navbar-nav {
    &:last-child {
      margin-left: 1rem;
    }
  }
  .search {
    @extend %navbar-circle;
    border: 1px solid;
    border-radius: 50%;
  }
}

.department-text {
  @include media-breakpoint-only(xs) {
    margin-bottom: .5rem;
    min-width: 100%;
  }
}