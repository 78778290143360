%legend-serif {
  @extend .h4;
  font-family: "Tiempos Fine", serif;
  font-weight: $font-weight-bold;
  margin-bottom: 1.5rem;
}

%legend-sans-serif {
  color: $gray-700;
  margin-bottom: 1.5rem;
}

fieldset {
  >div:not(:last-child) {
    margin-bottom: 1rem;
  }
  +.hr {
    margin-bottom: 1rem;
  }

  [class*="col-"] {
    position: relative;
  }
}

legend {
  float: none;
}

.form-control,
.form-select {
  box-shadow: $box-shadow-inset;
  &.other-amount {
    border-bottom-right-radius: $border-radius !important;
    border-top-right-radius: $border-radius !important;
    padding-left: 0.5rem;
  }
}

.form-select {
  &:invalid:not(:focus) {
    color: $input-placeholder-color;
  }
}

.form-submit {
  margin-top: 2.5rem;
  max-width: map-get($grid-breakpoints, md);
  margin-left: auto;
  margin-right: auto;
  button {
    width: calc(50% - 4px);
    &.d-none {
      +button {
        width: 100%;
      }
    }
  }
}


/* clears the ‘X’ from Internet Explorer */

input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}


/* clears the ‘X’ from Chrome */

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}