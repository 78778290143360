.funds {
  .row {
    margin-bottom: 1rem;
  }
  .btn {
    font-size: 1rem;
    height: 100%;
    @include media-breakpoint-up(sm) {
      @include fluid-value($sm-width, $max-width, 11px, 16px);
    }
    @include media-breakpoint-up($max-width) {
      font-size: 1rem;
    }
  }
  &:not(.active) {
    .btn-secondary {
      background-color: $primary;
      border-color: $primary;
      &:active {
        color: $white;
      }
    }
  }
  .tab-content {
    @extend .narrow-md;
  }
  .form-label {
    @extend %legend-serif;
  }
  .form-select {
    @extend %form-select-sm;
    border-color: $blue-lighter;
  }
}