:root {
  --bg-image: none;
}

html {
  @include fluid-value($sm-width, $max-width, 14px, 16px);
  @include media-breakpoint-up($max-width) {
    font-size: 16px;
  }
}

body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.AdminPageForm {
  .spinner {
    display: none !important;
  }
  .main {
    opacity: 1 !important;
  }
}

.page {
  &:not(.loading) {
    .spinner {
      display: none !important;
    }
    .main {
      opacity: 1 !important;
    }
  }
}

.main {
  flex-grow: 1;
  letter-spacing: 0.0125rem;
  padding: 0;
  position: relative;
  width: 100%;
  .container {
    padding: 3rem var(--bs-gutter-x, 20px);
    position: relative;
    z-index: 1;
    @include media-breakpoint-down(md) {
      margin-left: auto;
      margin-right: auto;
      width: 90%;
    }
  }
  &.has-background-image {
    &::after {
      background-image: var(--bg-image);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
    .container {
      background-color: rgba(white, 0.78);
    }
  }
}