$fv-plugins-bootstrap5-input-height: 38px;
$fv-plugins-bootstrap5-form-floating-height: 58px;

// See https://getbootstrap.com/docs/4.1/components/forms/#custom-styles
.fv-plugins-bootstrap5 {
    .fv-plugins-bootstrap5-row-invalid .fv-plugins-icon {
        color: #dc3545;
    }

    // Same as `valid-feedback`
    .fv-plugins-bootstrap5-row-valid .fv-plugins-icon {
        color: #198754;
    }

    .fv-plugins-icon {
        // Center the content
        align-items: center;
        display: flex;
        justify-content: center;

        // Size
        height: $fv-plugins-bootstrap5-input-height;
        width: $fv-plugins-bootstrap5-input-height;
    }

    .input-group ~ .fv-plugins-icon {
        // Need to have a bigger index than `.input-group .form-control:focus` (which is 2)
        z-index: 3;
    }
    // Move the icon for `input-group` to the right side
    .fv-plugins-icon-input-group {
        right: -#{$fv-plugins-bootstrap5-input-height};
    }

    /* Support floating label */
    .form-floating .fv-plugins-icon {
        height: $fv-plugins-bootstrap5-form-floating-height;
    }

    /* For horizontal form */
    .row {
        .fv-plugins-icon {
            right: 12px;
        }

        .fv-plugins-icon-check {
            top: -7px; /* labelHeight/2 - iconHeight/2 */
        }
    }

    /* Stacked form */
    &:not(.fv-plugins-bootstrap5-form-inline) {
        label ~ .fv-plugins-icon {
            top: 32px;
        }

        label ~ .fv-plugins-icon-check {
            top: 25px;
        }

        // Without labels
        label.sr-only ~ .fv-plugins-icon-check {
            top: -7px;
        }
    }

    /* Inline form */
    &.fv-plugins-bootstrap5-form-inline {
        .fv-plugins-icon {
            right: calc(var(--bs-gutter-x, 1.5rem) / 2);
        }
    }

    /* Remove the icons generated by Bootstrap 4.2+ */
    .form-control.fv-plugins-icon-input.is-valid,
    .form-control.fv-plugins-icon-input.is-invalid {
        background-image: none;
    }
}
