%button-indicator {
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;
  border-top: 1.25rem solid transparent;
  content: "";
  display: inline-block;
  height: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 100%;
  width: 0;
}

.btn {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  position: relative;
  text-transform: uppercase;

  &:disabled {
    opacity: 15%;
  }
}

.btn-secondary {
  color: $white;
  &:focus,
  &:hover {
    color: $white;
  }
  &.active,
  .btn-check:checked+& {
    background-color: $primary;
    border-color: $primary;
    color: $white;
  }
}

.btn-light {
  &.active,
  .btn-check:checked+& {
    background-color: $primary;
    border-color: $primary;
    color: $white;
  }
}

.btn-link {
  border-radius: 0;
  font-size: $font-size-base;
  text-transform: none;
}

.btn-check {
  +.btn {
    width: 100%;
  }
}

.btn-lg {
  text-transform: none;
}

.next {
  margin-left: auto;
  margin-right: auto;
}

.btn-next {
  span:last-child {
    font-weight: $font-weight-normal;
  }
}

.btn-cancel {
  &::before {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 28'><path fill='#{$secondary}' d='M24 14c0 6.609-5.391 12-12 12-3.578 0-6.953-1.578-9.234-4.328-0.156-0.203-0.141-0.5 0.031-0.672l2.141-2.156c0.109-0.094 0.25-0.141 0.391-0.141 0.141 0.016 0.281 0.078 0.359 0.187 1.531 1.984 3.828 3.109 6.312 3.109 4.406 0 8-3.594 8-8s-3.594-8-8-8c-2.047 0-3.984 0.781-5.437 2.141l2.141 2.156c0.297 0.281 0.375 0.719 0.219 1.078-0.156 0.375-0.516 0.625-0.922 0.625h-7c-0.547 0-1-0.453-1-1v-7c0-0.406 0.25-0.766 0.625-0.922 0.359-0.156 0.797-0.078 1.078 0.219l2.031 2.016c2.203-2.078 5.187-3.313 8.266-3.313 6.609 0 12 5.391 12 12z'></path></svg>");
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    display: inline-block;
    height: 1em;
    margin-right: 0.25rem;
    width: 1em;
  }
}