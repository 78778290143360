%text-serif {
  font-family: $font-family-serif;
}

%text-sans-serif {
  font-family: $font-family-sans-serif;
}

%gray-h2 {
  @extend %text-sans-serif;
  border-bottom: 1px solid;
  color: $gray-700;
  font-size: $font-size-lg;
  font-weight: $font-weight-normal;
  padding-bottom: 1em;
  text-align: left;
}

h6,
.h6 {
  font-family: $font-family-base;
  text-transform: uppercase;
}