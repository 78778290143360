.nav-item >a:not([class]) {
  display: block;
  padding: $nav-link-padding-y $nav-link-padding-x;
  @include font-size($nav-link-font-size);
  font-weight: $nav-link-font-weight;
  color: $nav-link-color;
  text-decoration: if($link-decoration==none, null, none);
  @include transition($nav-link-transition);
  &:hover,
  &:focus {
    color: $nav-link-hover-color;
    text-decoration: if($link-hover-decoration==underline, none, null);
  }
  // Disabled state lightens text
  &.disabled {
    color: $nav-link-disabled-color;
    pointer-events: none;
    cursor: default;
  }
}

// Navbar themes
//
// Styles for switching between navbars with light or dark background.
// Dark links against a light background
.navbar-light {
  .navbar-nav {
    .nav-item>a:not([class]) {
      color: $navbar-light-color;
      &:hover,
      &:focus {
        color: $navbar-light-hover-color;
      }
      &.disabled {
        color: $navbar-light-disabled-color;
      }
    }
    .show>.nav-link,
    .nav-link.active {
      color: $navbar-light-active-color;
    }
  }
}

// White links against a dark background
.navbar-dark {
  .navbar-nav {
    .nav-item>a:not([class]) {
      color: $navbar-dark-color;
      &:hover,
      &:focus {
        color: $navbar-dark-hover-color;
      }
      &.disabled {
        color: $navbar-dark-disabled-color;
      }
    }
    .show>.nav-link,
    .nav-link.active {
      color: $navbar-dark-active-color;
    }
  }
}