.gift-review {
  legend {
    @extend %legend-sans-serif;
  }
  .h4 {
    margin-bottom: .4168rem;
  }
  .form-submit {
    padding-top: 1.75rem;
  }
}

body:not(.quick-form) {
  .gift-review {
    h2 {
      @extend %gray-h2;
    }
    legend {
      @extend %legend-sans-serif;
    }
  }
}

.gift-recurrence {
  margin-bottom: 6%;
}

.payment-method {
  margin-bottom: 7.667%;
}

.tribute {
  margin-bottom: 1.5rem;
}