.note {
  margin-bottom: 0;
  margin-top: 0.5rem;
  min-width: 100%;
  &.disabled {
    display: none;
    input:disabled~& {
      display: block;
    }
  }
}