.banner-brand {
  margin-bottom: 4%;
  margin-left: auto;
  margin-right: auto;
  width: 25%;
  p {
    margin: 0;
  }
  img {
    height: auto;
    max-width: 100%;
  }
}