.gift-levels {
  @extend .narrow-md;
  font-size: $small-font-size;
  line-height: 1.42;
  margin-bottom: 11%;
  margin-top: calc(-11% + 1rem);
  h3 {
    @extend %legend-serif;
  }
  ol {
    padding: 1rem;
    >li:not(:last-child) {
      margin-bottom: 1rem;
    }
    ul {
      padding-left: 1.25rem;
      li:not(:last-child) {
        margin-bottom: .5rem;
      }
    }
  }
}