.gift-info {
  fieldset {
    &:not(:last-child) {
      margin-bottom: 11%;
    }
  }
  legend {
    @extend %legend-serif;
  }
  .tab-content {
    margin-top: 11%;
  }
  .hr {
    margin-top: -11%;
  }
}