.bg-tertiary {
  background-color: $tertiary !important;
}

.narrow {
  margin-left: auto;
  margin-right: auto;
  max-width: 24rem;
}

.narrow-md {
  margin-left: auto;
  margin-right: auto;
  max-width: 31.75rem;
}

.narrow-lg {
  margin-left: auto;
  margin-right: auto;
  max-width: 33.3333rem;
}

%form-control-sm {
  &:placeholder-shown {
    font-size: $input-btn-font-size-sm;
  }
  &:not(:placeholder-shown) {
    padding-bottom: .5325rem;
    padding-top: .5325rem;
  }
}

%form-select-sm {
  font-size: $input-btn-font-size-sm;
  padding-bottom: .75rem;
  padding-top: .75rem;
  text-transform: uppercase;
  // &:not(.is-invalid) {
  //   border-color: $blue-lighter;
  // }
  &[aria-invalid="false"],
  &:focus,
  &:valid,
  &.is-valid {
    //   border-color: $input-border-color;
    text-transform: none;
  }
  option {
    font-size: $font-size-base;
    text-transform: none;
  }
}